var arr = [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'الاجهزة',
    route: 'devices',
    icon: 'SmartphoneIcon',
    per: "devices"
  },
  {
    title: 'تصنيفات العملاء',
    route: 'tags',
    icon: 'TagIcon',
    per: "tags"
  },
  {
    title: 'الردود الجاهزة',
    route: 'templates',
    icon: 'MessageSquareIcon',
    per: "templates"
  },
  {
    title: 'الادوار / الصلاحيات',
    route: 'admins',
    icon: 'ShieldIcon',
    per: "admins"
  },
  {
    title: 'شرح الاستخدام',
    href: "https://youtu.be/812paAo3Rj0",
    icon: 'VideoIcon',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {
  if(user?.pers){
    if(user.pers.includes("*") || user.pers.includes(e.per) || !e.per){
      arr2.push(e)
    }
  }else{
    arr2.push(e)
  }
})

export default arr2
